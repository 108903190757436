@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Permanent+Marker&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@600&display=swap");

/* open sans and permenent marker */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&family=Permanent+Marker&display=swap");

/* new alternative */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&family=Oswald:wght@400;600;700&display=swap");

/* new fonts title  */
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;500;600&display=swap");

:root {
  /* --bg-color: #181a1b; */
  --bg-color: rgb(15, 15, 15);

  /* --text-color: #a8a095; */
  --text-color: rgb(225, 225, 225);
  --font-body: "Open Sans", sans-serif;
  --font-title: "Be Vietnam Pro", sans-serif;
  /*--font-body: "Lato", sans-serif; */
  /* --font-title: 'Oswald', sans-serif; */
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

/* ===================
    general styles
======================= */

body {
  padding: 0;
  margin: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
  font-family: var(--font-body);
}

h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}
section {
  background-color: var(--bg-color);
  font-family: var(--font-body);
}
.container {
  min-height: 75vh;
  background-color: var(--bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.banner,
.images,
.images1 {
  background-color: #bfbfbf;
  background-position: top;
  background-attachment: fixed;
  background-size: auto;
  background-blend-mode: multiply;
}


.images,
.images1 {
  display: flex;
  min-height: min-content;
  height: 40vh;
  background-attachment: scroll ;
  background-size: 100%;
  background-repeat: no-repeat;
}

.kebab{
  object-fit: cover;
  max-width: 100%;
  height: 100%;
}

.banner {
  display: flex;
  align-items: center;
  min-height: 100vh;
  transition: all 0.5s;
}

.container-title,
.container-text,
.title {
  transition: all 0.25s;
}

#booking .container-wrapper{
  margin-top: 4em;
}

/* button */

.cta {
  position: relative;
  margin: auto;
  margin-top: 2em;
  padding: 1em 2em;
  border-radius: 50px;
  transition: all 0.2s ease;
  border: none;
  background: #be291c;
  background: none;
}

/* button margin top */

.cta:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  border-radius: 50px;
  background: rgb(121, 35, 30);
  background: #be291c;
  width: 45px;
  height: 45px;
  transition: all 0.3s ease;
}

.cta span {
  position: relative;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: var(--text-color);
}

.cta svg {
  position: relative;
  top: 0;
  margin-left: 10px;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: var(--text-color);
  stroke-width: 2;
  transform: translateX(-5px);
  transition: all 0.3s ease;
}

.cta:hover {
  cursor: pointer;
}

.cta:hover:before {
  width: 100%;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active {
  transform: scale(0.95);
}

/*======== nav bar =========== */

.nav-wrapper {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  min-height: 100vh;
  background-color: var(--bg-color);
  display: flex;
  visibility: hidden;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 0.5s;
  animation: appear 1s ease-out;
}

.nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.open-nav {
  visibility: unset;
  opacity: 1;
  transform: translateX(0);
}

.nav a {
  text-decoration: none;
  color: var(--text-color);
  font-family: var(--font-body);
  font-size: 1.25rem;
  padding: 0.75em 1em;
  letter-spacing: 2px;
  text-shadow: 3px 3px 5px rgb(26, 26, 26);
}

/* letter spacing between nav links  */

.nav-btn,
.nav-cross {
  z-index: 100;
  position: fixed;
  font-size: 1.5rem;
  margin: 0.5em 1em;
  cursor: pointer;
  animation: rotateIn 1s forwards;
}

.logo {
  height: 40px;
  align-self: center;
  margin: 0.5em;
}

.fb-icon {
  font-size: 2rem;
}
.logo:hover {
  cursor: pointer;
}

/* ------nav bar---------- */

.title-container {
  margin: 0 auto;
  text-align: center;
  animation: appear 1s ease-out;
}

.title {
  font-family: var(--font-title);
  font-size: 2.25rem;
  color: var(--text-color);
  text-transform: uppercase;
}

.sub-title {
  font-size: 1.5rem;
  font-family: var(--font-title);
  /* margin-top: -0.5em; */
}

.homepage__date {
  display: block;
  font-size: 0.5em;
  font-family: var(--font-title);
  font-weight: 300;
  letter-spacing: 1.4px;
  margin-top: 0.3em;
  text-transform: uppercase;
}
/* containers */

.container {
  text-align: center;
}

.text-wrapper {
  grid-area: text;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
}

.imgs-wrapper {
  display: none;
}

.container-title {
  background: url("./images/stroke.png");
  background-repeat: no-repeat;
  background-position: 60% 70%;
  background-size: 100% 50%;
  grid-area: title;
  font-family: var(--font-title);
  font-size: 2.75rem;
  font-weight: 300;
  text-shadow: 3px 3px 5px rgb(26, 26, 26);
  padding-bottom: 0.25em;
  position: relative;
  text-transform: uppercase;
  padding-top: 1em;
}

.container-text {
  grid-area: text;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 1.4;
  font-size: 1rem;
  margin-bottom: 1em;
  padding: 0 1em;
  text-align: justify;
}

/* images */



.images1 {
  background-image: url("./images/img1.jpg");
  background-color: #777777;
  background-size: cover;
  background-blend-mode: multiply;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 3em 0; */
}

.images1 .container-title {
  background: transparent;
  font-family: var(--font-body);
  font-weight: 700;
}



.images1 h1 {
  padding: 0;
}

.hours-text {
  text-transform: uppercase;
  font-family: var(--font-body);
  font-weight: 700;
  font-size: 1.25rem;
  text-shadow: 3px 3px 5px rgb(26, 26, 26);
}

.contact-links {
  padding: 1em 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.find-us {
  grid-area: imgs;
  justify-self: stretch;
  padding: 0.5em 1rem;
}

.google-map {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

.pin {
  display: flex;
  align-items: center;
  width: 180px;
}

.pin-icon {
  font-size: 2rem;
  color: #c51d17;
}

.pin-text {
  font-size: 1.3em;
}

.footer {
  padding: 2em 0.5em;
  border-top: 2px solid rgba(30, 30, 60, 0.25);
  border-radius: 50px;
  margin-top: 1em;
  text-align: center;
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.subpage__container {
  max-width: 1400px;
  margin: 0 auto;
  text-align: justify;
}

.subpage__image {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center center;
}
.subpage__image--sake {
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center center;
}
.contact--link {
  color: white;
  text-decoration: none;
  padding: 0.25rem;
}
.img--about {
  background-image: url("./images/aboutbanner.png");
}
.img--ourfood {
  background-image: url("./images/sake1.png");
}
.img--charcoal {
  background-image: url("./images/charcoalg.png");
}
.img--ken0 {
  background-image: url("./images/ken0.jpg");
}
.img--ken1 {
  background-image: url("./images/ken1.jpg");
}
.img--ken2 {
  background-image: url("./images/ken2.jpg");
}
.img--ken3 {
  background-image: url("./images/ken3.png");
}
.subpage__container {
  width: 100%;
}
.column__para {
  display: flex;
}
.two-column {
  margin-top: -110px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}
.column {
  padding: 20px 40px 70px 0;
  width: 50%;
}
.column__title {
  font-family: var(--font-title);
  padding-top: 2rem;
  text-transform: uppercase;
}
.column__text {
  margin-top: 16px;
}
.column__heading {
  font-family: var(--font-title);
  font-size: 7rem;
  margin-bottom: 0.25em;
  text-transform: uppercase;
}
.language__jpp {
  font-family: var(--font-title);
  font-size: 6rem;
  margin-bottom: 0.25em;
}
.heading--ourfood {
  font-size: 6.6rem;
}

.heading--ken1 {
  font-size: 4.1rem;
  margin-top: 2rem;
}
.heading--ken2 {
  font-size: 4.2rem;
  margin-top: 2rem;
}
.column__heading--hidden {
  color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.date--page {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem;
}
.date--page-title {
  width: 100%;
  flex: 20%;
  font-weight: bold;
}
.date--page-child {
  width: 100%;
  flex: 80%;
}
.zerozerotimeline {
  font-size: 0.8em;
}
/* media queries  */

@media (min-width: 500px) {
  .title {
    font-size: 3rem;
  }
  .sub-title {
    font-size: 2rem;
  }
}

@media (min-width: 700px) {
  .title {
    font-size: 4rem;
  }
  .sub-title {
    font-size: 2.5rem;
  }
  .container {
    min-height: 65vh;
  }

  .images{
    background-image: url("./images/img2.jpg");
  } 
  .images, .images1 {
    background-attachment: fixed;
  } 
  .kebab{
    display: none;
  }

  .title {
    font-size: 4rem;
  }

  .container {
    min-height: 65vh;
  }

  .title {
    font-size: 4rem;
  }

  .banner, .images, .images1{
    background-size: cover;
  }

  .container {
    min-height: 65vh;
  }

  .nav-wrapper {
    min-height: min-content;
    background-color: transparent;
    visibility: visible;
    transform: translateX(0);
    opacity: 1;
  }

  .nav-visible {
    background-color: var(--bg-color);
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.5);
  }

  .nav {
    justify-content: space-between;
    flex-direction: row;
    max-width: 1400px;
    padding: 0 0.5em;
    margin: 0 auto;
  }

  .nav a,
  .nav p {
    text-decoration: none;
    color: var(--text-color);
    font-family: var(--font-body);
    font-size: 1.25rem;
    padding: 0.75em 1em;
    cursor: pointer;
  }

  .fb-icon {
    position: absolute;
    top: 25%;
    margin-left: 0.5em;
  }
  .fb-link {
    position: relative;
    margin-right: 1em;
  }

  .fb-link::after,
  .fb-link::after {
    content: "find us on facebook!";
    font-size: 0.8rem;
    text-align: left;
    position: absolute;
    right: 50%;
    transform: translateX(70%);
    opacity: 0;
    transition: all 0.25s ease-out;
  }

  .fb-link:hover::after,
  .fb-link:hover::after {
    opacity: 1;
    transform: translateX(0);
  }

  .nav-btn,
  .nav-cross {
    display: none;
  }

  .container-title {
    font-size: 4rem;
  }

  .container-text {
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 1px;
    font-weight: 400;
  }
  #booking .container-text {
    font-size: 1rem;
    letter-spacing: 0.5px;
  }

  .hours-text {
    font-size: 1.5rem;
  }
  .find-us h2 {
    font-size: 2rem;
    margin-top: 1em;
  }
  .find-us p {
    font-size: 1.2rem;
  }
  .contact-links p {
    margin-left: 0.5em;
  }

  .nav {
    justify-content: space-between;
    flex-direction: row;
    max-width: 1400px;
    padding: 0 0.5em;
    margin: 0 auto;
  }

  .nav a {
    text-decoration: none;
    color: var(--text-color);
    font-family: var(--font-body);
    font-size: 1.25rem;
    padding: 0.75em 1em;
  }

  .nav-btn,
  .nav-cross {
    display: none;
  }

  .container-title {
    font-size: 4rem;
  }

  .container-text {
    font-size: 1rem;
    line-height: 1.6;
    letter-spacing: 1px;
    font-weight: 400;
  }
  #booking .container-text {
    font-size: 1rem;
    letter-spacing: 0.5px;
  }

  .hours-text {
    font-size: 1.5rem;
  }
}

@media (min-width: 1000px) {
  .title {
    font-size: 6rem;
  }
  .sub-title {
    font-size: 4rem;
  }

  .container-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    grid-template-areas: "text imgs";
  }

  .google-map {
    height: 40vh;
    width: 100%;
    margin: 0 auto;
  }

  .invert-container {
    grid-template-areas: "imgs text";
  }

  .imgs-wrapper {
    width: max-content;
    grid-area: imgs;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2em;
    padding: 2em 0.5em;
  }
  .imgs-wrapper img {
    width: 250px;
    height: 200px;
    object-fit: cover;
    stroke: white;
  }

  .images1 {
    display: flex;
  }

  #booking .imgs-wrapper {
    display: none;
  }
}

/* DESKTOPS LARGE SCREENS */
@media (max-width: 1440px) {
  .column__heading--hidden {
    display: none;
  }
  .column {
    padding-bottom: 20px;
    width: 90%;
  }
  .column:last-child {
    padding-bottom: 8rem;
  }
}
@media (max-width: 1200px) {
  .column__heading--hidden {
    display: none;
  }
  .column {
    padding-bottom: 20px;
    width: 90%;
  }
  .column:last-child {
    padding-bottom: 8rem;
  }
}

/* SMALL SCREENS, LAPTOPS */
@media (max-width: 1024px) {
  .column__heading--hidden {
    display: none;
  }
  .column {
    padding-bottom: 20px;
    width: 80%;
  }
  .two-column {
    flex-direction: column;
  }
}

/* IPAD */
@media (max-width: 768px) {
  .column__heading {
    font-size: 4rem;
  }
  .column {
    padding: 60px 10px 10px 10px;
    width: 90%;
  }
  .heading--ken1,
  .heading--ken2 {
    font-size: 2.2rem;
    padding-top: 0px;
    margin-top: 22px;
  }
  .column:nth-child(even) {
    padding-top: 0;
  }
  .column__title {
    padding-bottom: 1rem;
  }
  .date--page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-bottom: 2rem;
  }
}
/* MOBILE PHONE */
@media (max-width: 480px) {
}

/* animations */

@keyframes rotateIn {
  from {
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
